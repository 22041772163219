import apiProvider from "@/utils/api";
import axios, { AxiosPromise } from "axios";
import {toUrl} from "@/utils/helpers"
const rootPath = 'operationfamilies';

class OperationTypeService {
  
    // public async getFilterOperationTypes(lang:string): Promise<any> {
    //     return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/filteroperationTypes${toUrl({lang})}`);
    // }
    public async getOperationTypes(lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}${toUrl({lang})}`);
    }
    
    public async findOperationTypeByKey(operationKey:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/${operationKey}${toUrl({lang})}`);
    }
    public async getSubOperationTypes(operationTypekey:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/suboperationtypes${toUrl({lang,operationTypekey})}`);
    }
    
    public async ListOperationTypesWithDescendenants(operationType:number,bopModelId:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/withdescendants${toUrl({lang,operationType,bopModelId})}`);
    }
    public async ListOperationFamiliesAndCoefficients(bopModelId:string,lang:string,operationFamilyKey:string|null = null,coefficientOption:string|null = null): Promise<any> {
        let query = {lang,bopModelId};
        if(coefficientOption){
            query['coefficientOption'] = coefficientOption;
        }
        if(operationFamilyKey){
            query['operationFamilyKey'] = operationFamilyKey;
        }
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/coefficients${toUrl(query)}`);
    }
    public async listOperationFamiliesByParentAndBopModel(bopModelId:string,operationFamilyParentKey:string,lang:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/byParentAndBopModel${toUrl({lang,operationFamilyParentKey,bopModelId})}`);
    }

    public async getOperationFamilyRootFromDescendent(descendantOperationKey:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/getOperationFamilyRootFromDescendent/${descendantOperationKey}`);
    }



    
}

export default new OperationTypeService();